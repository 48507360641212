<template>
<div class="about">

    <div class="modal" :data-night="night" :class="[pageStatus, modalStatus]">
        <div v-if="hasData" class="modal-body">

            <h1>&gt; {{ api.heading }}</h1>

            <div class="content">
                <div class="column" v-html="api.description_1"></div>
                <div class="column" v-html="api.description_2"></div>
            </div>

            <div class="skills">
                <div class="column" v-html="api.column_1"></div>
                <div class="column" v-html="api.column_2"></div>
                <div class="column" v-html="api.column_3"></div>
            </div>

        </div>
        <Loader v-else />
    </div>

</div>
</template>

<script>
import Loader from '@/components/DataLoader.vue'

    export default {
        name: "About",

        components: {
            Loader
        },

        data: () => ({
            api: "",
            pageTitle: 'About | ',
            pageStatus: '',
            modalStatus: 'modal-open',
            night: false,
            hasData: false
        }),

        created () {
            this.night = this.$store.getters.night
            this.openPage()
            document.title = this.pageTitle + this.$store.getters.siteTitle
            this.getContent()
        },

        computed: {
            getPageStatus () {
                return this.$store.getters.pageStatus
            },
            getNight () {
                return this.$store.getters.night
            },
            returnApi () {
                return this.$store.getters.apiContent
            }
        },

        watch: {
            getPageStatus: function () {
                this.pageStatus = this.$store.getters.pageStatus
            },
            getNight: function(status) {
                this.night = status
            },  
            returnApi: function(data) {
                this.api = JSON.parse(data[0].content)
                this.hasData = true;
            }
        },

        methods: {
            openPage() {
                this.$store.commit('updatePage', 'page-open')
            },
            getContent () {
                this.$store.dispatch('getContent', 'about')
            }
        }
    }
</script>

<style lang="scss">
.about {
    .modal {
        background: rgba(93,118,127,0.9);
        background: linear-gradient(180deg, rgba(39,51,56,0.9) 0%, rgba(93,118,127,0.9) 100%);
        color: $off-white;
        @include shadow-5();
        z-index: 2;
        width: 90%;
        padding: 5%;
        max-width: 1200px;
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: all .4s ease;

        @media screen and (min-width: 991px) {
            padding: 40px;
        }

        &[data-night="true"] {
            background: rgba(59, 65, 78,0.9);
            background: linear-gradient(180deg, rgba(25, 28, 34, 0.9) 0%, rgba(59, 65, 78,0.9) 100%);
        }

        &.modal-open {
            opacity: 0;
            visibility: hidden;
            animation: adnimatedModalOpen 1s ease;
            animation-fill-mode: forwards;
        }

        &.page-closed,
        &.modal-closing {
            animation: animatedModalClosed .3s ease;
            animation-fill-mode: forwards;
        }

        @keyframes adnimatedModalOpen {
            0% {
                opacity: 0;
                visibility: hidden;
                transform: scale(.8);
            }
            100% {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
            }
        }

        @keyframes animatedModalClosed {
            0% {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
            }
            100% {
                opacity: 0;
                visibility: hidden;
                transform: scale(.8);
            }
        }

        .modal-body {
            position: relative;
            display: flex;
            flex-direction: column;

            h2,h3,h4,h5,h6 {
                margin-top: 0;
                margin-bottom: 10px;
                font-size: 1.8rem;

                @media screen and (min-width: 576px) {
                    font-size: 2rem;
                }
            }

            p {
                margin-top: 0;
            }

            h1 {
                margin-top: 0;
                font-size: 2rem;

                @media screen and (min-width: 576px) {
                    font-size: 2.8rem;
                }
            }

            .content {
                display: flex;
                flex-direction: column;

                @media screen and (min-width: 768px) {
                    flex-direction: row;
                }

                .column {
                    padding: 0;

                    @media screen and (min-width: 768px) {
                        padding: 0 20px;
                    }

                    &:first-of-type {
                        padding-left: 0;
                    }

                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }

            .skills {
                display: flex;
                flex-direction: column;
                padding-top: 30px;
                gap: 10px;

                @media screen and (min-width: 768px) {
                    flex-direction: row;
                }

                @media screen and (min-width: 991px) {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                }

                .column {
                    padding: 15px;
                    font-style: italic;
                    background: rgba(25, 28, 34, 0.4);
                }
            }
        }
    }
}
</style>