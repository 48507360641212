<template>
    <div class="loading">Hold your horses, grabbing the data...</div>
</template>

<script>
    export default {
        name: "Data Loader"
    }
</script>

<style lang="scss">
.loading {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: loading 1s infinite ease;
    border: 1px solid transparent;
}

@keyframes loading {
    0% {
        opacity: 1;
        font-size: 22px;
    }
    50% {
        opacity: .4;
        font-size: 21px;
    }
    100% {
        opacity: 1;
        font-size: 22px;
    }
}
</style>